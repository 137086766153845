import React, { Component } from "react";
import CategoryPage from "./CategoryPage";

import { inject } from "mobx-react";
import { withRouter } from "react-router-class-tools";

@inject("UIStore")
class CategoryTrudat extends Component {
  render() {
    this.props.UIStore.setCurrentAction(['category', 'category-trudat']);

    var props = { ...this.props };
    props.match.params.slug = "343,trudat";

    return <CategoryPage {...props} />;
  }
}
export default withRouter(CategoryTrudat);